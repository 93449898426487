/* Common Css */
@import url('https://fonts.googleapis.com/css2?family=Poppins&display=swap');
*{padding:0;margin:0;box-sizing:border-box}
body{font-family:'Poppins',sans-serif;font-size:16px;color:#0b0a0a;}
.container_fluid{width:100%;clear:both}
.container{width:100%;max-width:1280px;margin:0 auto}
li{list-style-type:none}
a{text-decoration:none;color:black}
img{max-width:100%; display: block; }
/* ul li{display:inline-block} */

/* .mobile_menu{displ;ay: none;} */
.Logout_coursor{cursor: pointer;}
.filtterSwitch {
  display: flex;
  flex-direction: column;
  /* width: 15%; */
  /* border: 1px solid #efefef38; */
  float: right;
  font-size: 15px;
  padding: 12px 0px;
  /* box-shadow: -1px -1px 9px #bcbcbc33; */
  font-weight: 600;
  align-items: flex-end;
}
/* Page 1 and 2 */
.section_block{width: 100%; float: left; min-height: 500px;  }
.header_block{width:100%;float:left;clear:both; box-shadow: 0 0 20px rgba(0, 0, 0, 0.1); position: relative;background-color:#005099}
.header{width:100%;float:left;clear:both;padding:1% 0;font-family:'poppins'}
input[type=search]::placeholder{color: black}
.header_logo{width:4%;float:left}
.header_logo h4{color:#005099;background-color:#FFF;width:30px;height:30px;line-height:2.2;border-radius:50%;text-align:center;font-size:13px}
.header_categories{float:right;text-align:center;max-width: 90%; min-width: 77%;}
.message_btn_container{width:3.5%;float:right}
.message_btn_container .fa.fa-comment{color:#eb711e;display:block;font-size:28px}
.sell_btn_container{width:7%;float:right; background-color: #fff; border-radius: 7px; padding: 3px;}
.View_AllHeding{
  display: flex;
}
.sell_btn_container>a{ width: 100%;}
.SearchTOLocation {
  width: 14%;
  margin-left: 16px;
  padding: 3px;
  border: 1px solid white;
  display: inline;
  font-weight: 500;
  background: #efefef;
  border-radius: 25px;
}
.sell_btn_container button{background-color:#fff;border-radius:7px;border:0;outline:0;width:72%;padding:5%;box-shadow:0 4px 4px 2px #e4e4e4}
.sell_btn_container span{text-align: left;font-family:poppins;width:60%}

.location_btn_container { float: right; position: relative; padding: 3px 8px 3px 3px; background-color:#e9e9e9; border-radius: 15px; margin: 0 10px; }
.location_btn_container>input[type="search"]{width:80%;padding:5% 0 5% 18%;background-color:#e9e9e9;border-radius:15px;border:0;outline:0}
.location_btn_container::before{content:'\f041';color:black;font-family:'fontawesome';font-size:16px;margin: 0 5px;}

.search_btn_container{float:right;width:25%;position: relative;}
.search_btn_container ::placeholder {font-size: 14px;}
.search_btn_container>input[type="search"]{width:100%;padding:3% 0 3% 13%;border-radius:15px;border:0;background-color:#e9e9e9;outline:0}
/* .search_btn_container::before{content:'\f002';color:#131313;position:absolute;top:7px;left:11px;font-family:'fontawesome';font-size:14px;opacity:.7;font-weight:100} */
.menu_btn_container{width:6%;float:right}
.menu_btn_container .fa.fa-bars{font-size:27px;padding-top:4%; color:#eb711e;}

/* related add section */
.related_ads{width:100%;float:left;clear:both; margin-bottom: 25px;}
.related_ads h2{font-weight:400; margin-bottom: 13px;}

.related_ads_heading{position:relative;width:89%; clear: both; padding-top: 20px;}
.arrow_heading_left{position:absolute;right:35px;top:-6px;padding:14px 0;color:#d26114;font-size:12px}
.arrow_heading_left .fa.fa-chevron-left{background-color:#e9e9e9;width:24px;height:24px;border-radius:50%;line-height:2;display:block;text-align:center}
.arrow_heading_right{position:absolute;right:0;top:-6px;padding:14px 0;color:#d26114;font-size:12px}
.arrow_heading_right .fa.fa-chevron-right{background-color:#e9e9e9;width:24px;height:24px;border-radius:50%;line-height:2;display:block;text-align:center}
.relatedtext{float:left;    width: 60%;padding: 11px 6px 0 9px;}
.addViesad{padding-top: 20px;     color: #585656;
  font-size: 19px;}
.search_By_location{float: left;
  width: 18%;
  position: relative;}
  .search_By_location > input[type="search"] {
    width: 100%;
    padding: 4% 0 4% 5%;
    margin: 0px 10px;
    border-radius: 4px;
    font-size: 17px;
    border: 2px solid #005099;
    background-color: #fffcfc;
    outline: 0;
}
.ads_focus > li{ width:32%; min-height: 150px;float: left; border:1px #bdbdbd solid;margin-right:2%;position:relative; margin-bottom: 30px;}
.related_ads li:nth-child(3n) {margin-right: 0;}
.related_ads li:nth-child(3n+1){clear: left;}
.related_ads .arrow_slider_left{color:#fff;background-color:#d26114;height:46px;width:21px;text-align:center;font-size:11px;line-height:4.5;font-weight:400;position:absolute;left:0;top:66px}
.related_ads .arrow_slider_right{background-color:#d26114;height:46px;width:21px;text-align:center;font-size:11px;line-height:4.5;font-weight:400;position:absolute;left:186px;top:66px}
#featured{position:absolute;top:4px;left:20px;background-color:white;border-radius:5px;padding:4px 6px;margin-left:7px;font-family:poppins;outline:0;border:0;font-weight:200;font-size:10px}
#heart{position:absolute;top:4px;left:134px;background-color:white;border-radius:7px;padding:1px 10px;border:0;outline:0}
.one{font-size:20px;font-weight:300}
.two {
  font-size: 16px;
  font-weight: 300;
  color: black;
  padding-top: 6px;
  margin-bottom: 9%;
}
.three {
  font-size: 12px;
  font-weight: 500;
  padding-top: 11px;
}
.three>span{text-align:end}
.relatedtext p span{margin-left:48px}
.related_ads li:nth-child(3n){margin:0}

.product_images{width: 50%; float: left;}
.product_images img{max-height: 150px;}

  /* footer section */
  .footer{ background-color:#005099;clear:both;float:left;width:100%; color: #fff;}
  .footer ul{width:100%}
  .footer ul> li{width:20%;float:left;margin:30px auto; font-weight: 300;}
  .footer ul li>div>h5{margin-bottom:7px;font-size:15px; font-weight: 600;}
  .footer ul li:last-child h5{margin-bottom:15px;font-size:15px; font-weight: 600;}
  .footer ul li>div>p{font-size:13px; font-weight: 500; padding-bottom: 3px;}
  .footer ul li>div>p>a{font-size:13px; color: white;}
  .footer ul li>div{margin:0 auto;width:fit-content}
  .footer ul li:last-child{padding-right:10%}
  .social_media {width: 100%; display: flex; gap: 10px;float: left;}
  .social_media .fb, .insta, .gmail {display: inline-block;}
  .fb>i {color: white;}
  .social_media .fa.fa-facebook {
    background-color: blue;
    height: 25px;
    width: 25px;
    border-radius: 50%;
 line-height: 1.2;;
    text-align: center;


}
ul.ads_focus {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

ul.ads_focus > .card {
  width: 22%;
  margin-right: 38px;
  height: auto;
  margin-bottom: 20px;
  border-radius: 4px;
  border: 1px solid gray;
  padding: 9px 11px;
}
.hideContainer{
  display: none;
}
.itemCard{
  width: 60%;
  margin-right: 38px;
  height: auto;
  margin-bottom: 20px;
  border-radius: 4px;
  border: 1px solid gray;
  padding: 9px 11px;
}
.itemCard a{ width: 100%;}
#item{
width: 100%;
}
#item > .itemCard{width: 100%;  margin-top: 20px;}
div#AdsFocus .owl-item{width: 291px !important;
  margin-right: 38px !important;}
  ul#sasd_sdss .owl-item{width: 291px !important;
    margin-right: 38px !important;}

  ul#sasd_sdss button.owl-prev span {
      color: black;
      border-radius: 3px;
      box-shadow: -2px 2px 6px #15417452;
      padding: 8px;
      background: #edf3ff;
      font-size: 37px;
  }

  ul#sasd_sdss button.owl-next span {
    color: black;
    border-radius: 3px;
    box-shadow: 2px 3px 6px #15417452;
    padding: 8px;
    background: #edf3ff;
    font-size: 37px;
}
ul#sasd_sdss button.owl-next{
  top: 132px;
}
ul#sasd_sdss button.owl-prev{
  top: 132px;
}
div#AdsFocus .owl-stage-outer {
  width: 88%;
  border: 1px solid #c1bcbc;
  background: #f9f9f9;
  margin: auto;
}
.itemCard h2 {
  font-weight: 600;
  font-size: 29px;
  margin-bottom: 0;
  color: #000000;
  user-select: none;
}
.itemCard p {
  margin: 9px 0px;
  font-family: 'Open Sans Condensed', sans-serif;
  color: rgba(0, 0, 0, 0.64);
  font-size: 16px;
  user-select: none;
  font-weight: 500;
}



ul.ads_focus > .card a {
  width: 100%;
  
}
.image_productListing {
  height: 172px;
  max-width: 75%;
  background-size: contain;
  overflow: hidden;
  background-repeat: no-repeat;
  margin: 0px auto 18px;
}
ul.ads_focus > .card h2 {
  font-weight: 600;
  font-size: 29px;
  margin-bottom: 0;
  color: #000000;
  user-select: none;
}
ul.ads_focus > .card p {
  margin: 9px 0px;
  font-family: 'Open Sans Condensed', sans-serif;
  color: rgba(0, 0, 0, 0.64);
  font-size: 16px;
  user-select: none;
  font-weight: 500;
}


/* CARD 1 */
.card_1 {
  background: #ffffff;
  transition: 1s;
}





  .fa.fa-envelope{text-align:center;vertical-align:middle;border-radius:50%; color: black; background-color: #fff;}
  .fa.fa-instagram{text-align:center;vertical-align:middle;border-radius:50%;color: black; background-color: #fff;}

 /* slider */
 .arrow_slider_left{color:#fff;background-color:#d26114;height:46px;width:21px;text-align:center;font-size:11px;line-height:4.5;font-weight:400;position:absolute;left:0;top:165px}
 .arrow_slider_right{color:#fff;background-color:#d26114;height:46px;width:21px;text-align:center;font-size:11px;line-height:4.5;font-weight:400;position:absolute;right:0;top:165px}


 .lds-ring {
  display: inline-block;
  position: relative; top: 50%; margin-top: -40px;
  width: 80px;
  height: 80px;
}
.lds-ring div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 64px;
  height: 64px;
  margin: 8px;
  border: 8px solid #ff0000;
  border-radius: 50%;
  animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: #ff0000 transparent transparent transparent;
}
.lds-ring div:nth-child(1) {
  animation-delay: -0.45s;
}
.lds-ring div:nth-child(2) {
  animation-delay: -0.3s;
}
.lds-ring div:nth-child(3) {
  animation-delay: -0.15s;
}
@keyframes lds-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.drawer_dropdown .MuiButtonBase-root{padding: 2px 0 0 20px; color: #ffffff;}

.loader_outer{background-color: rgba(255,255,255,1); width: 100%; height: 100%; position: fixed; left: 0; top: 0; z-index: 9999; text-align: center;}
.loader_outer_visiblity{background-color: #1d1d1d52; width: 100%; height: 100%; position: fixed; left: 0; top: 0; z-index: 9999; text-align: center;}
/* page1 */

/* main banner */
.banner_section{position:relative; }
.banner_section .banner_text{position:absolute;top: 201px;left:174px;font-size:43px;color:#fff}
.banner_section .banner_text h3{font-weight:300}
.banner_section .banner_text h2{font-weight:600;font-size:51px;width:72%}
.banner_section .banner_text h2 span{color:#012e6d;font-weight:600}
.banner_text button {
    padding: 12px 15px;
    border-radius: 9px;
    color: #21487f;
    background-color: #fff;
    font-weight: 600;
    font-size: 14px;
    box-shadow: -2px 3px 9px #078cf9;
    border: none;
    margin-top: 9%;
    font-family: poppins;
}
.right i,button,.location,.searching,.searching1{display:inline-block}
span{float:right}
.banner_section img{max-width:100%}
.banner_section_middle {margin: 40px 0;}

/* jobs */
.jobs{width:100%}
.jobs ul{width:100%}
.jobs ul li{width:23.9%;display:inline-block;margin-right:1.1%;padding:6px 18px;border:#b9b9b9 1px solid}
.jobs ul li h5,h6{font-weight:300;font-size:13px}
.jobs ul li h6{font-weight:300;font-size:12px;margin-bottom:6px}
.jobs ul li p{font-size:11px;font-weight:300;padding-top:1px}
.jobs ul li p>i{padding:0 8px;font-size:12px;color:#dc6a1a}
.jobs ul li h5 span{font-size:9px}
.jobs ul li:last-child{margin:0}
.jobs h2{margin:13px 0;font-weight:inherit}
 .Detailsprice{ font-weight: 700; font-size: 39px;padding: 10px 0;}
 .detailsTitle {
  font-size: 19px;
  color: #666666;
}
.MX-ycs_cs{width: 31.5% !important}
/* center banner */
.banner_section img {max-width: 100%; width: 100%;}
.banner_section .banner_nav {position: absolute; bottom: 24%; right: 19%; font-size: 43px;}
.banner_section .banner_nav h3{font-weight:300}
.banner_nav h2{font-size:50px;text-align:right;font-weight:600}
.banner_section .banner_nav h2 span{color:#800000;font-weight:600;padding-left:18px}
.banner_section .banner_nav button{border:0;padding:14px 30px;border-radius:9px;color:#800000;font-weight:700;margin-left:154px;background-color:#fff;font-size:14px}
.banner_section img{max-width:100%}

/* mid section */
.mid_section{width:100%;clear:both;margin:24px 0; float: left;}
.mid_section_lft{width:25%;float:left; }
.all_border{border:#b9b9b9 1px solid;height:100%;min-height:522px; position: relative;}
.text{padding:22px}
.all_border .text h4{font-size:21px;font-weight:300}
.text p{font-size:14px;font-weight:300}
.text h6{font-size:10px;padding-top:17px}
.all_border #heart{position:absolute;top:8px;left:261px;background-color:white;border-radius:7px;padding:4px 11px;border:0;outline:0}
.fresh_recommendation_top {width:100%;}
.fresh_recommendation_top li { display: inline-block; width: 49%; border: 1px #bdbdbd solid; margin-right: 12px; position: relative; }
.fresh_recommendation_top .arrow_slider_left{color:#fff;background-color:#d26114;height:46px;width:21px;text-align:center;font-size:11px;line-height:4.5;font-weight:400;position:absolute;left:0;top:66px}
.fresh_recommendation_top .arrow_slider_right{  background-color: #d26114;   height: 46px;   width: 21px;   text-align: center;   font-size: 11px;   line-height: 4.5;   font-weight: 400;   position: absolute;   left: 195px;   top: 66px; }
.fresh_recommendation_top li:last-child{margin:0}
.fresh_recommendation_top img { max-width: 100%; width: 50%; float: left; }
.mid_section_lft h2{font-weight:inherit;margin-bottom:13px; position: relative;}
.mid_section_rgt h2{font-weight:inherit;margin-bottom:13px; position: relative;}
.mid_section_rgt{width:69%;float:right}
.mid_section_rgt2{width:100%;margin:8px 0;border:#b9b9b9 1px solid; position: relative;}
.mid_section_rgt2 .sec_text{width:30%;float:left;padding:27px}
.mid_section_rgt2 img{width:70%;vertical-align:middle; position: relative;}
.mid_section_rgt3{width:100%}
.mid_section_rgt3 ul{width:100%}
.mid_section_rgt3 ul li{width:32.25%;display:inline-block;margin-right:1.1%;padding:6px 18px;border:#b9b9b9 1px solid}
.mid_section_rgt3 ul li h5,h6{font-weight:300;font-size:13px}
.mid_section_rgt3 ul li h6{font-weight:300;font-size:12px;margin-bottom:6px}
.mid_section_rgt3 ul li p{font-size:11px;font-weight:300;padding-top:1px}
.mid_section_rgt3 ul li p>i{padding:0 8px;font-size:12px;color:#dc6a1a}
.mid_section_rgt3 ul li h5 span{font-size:9px}
.mid_section_rgt3 ul li:last-child{margin:0}
.mid_section_rgt3 h2{margin-bottom:13px 0;font-weight:inherit}
.mid_section_lft img{max-width:100%;width:100%}
.logo_home {
	float: left;
	padding: 40px 0;
  width: 20%;
}
.category_name{ padding: 15px 0 0;}
/* align icons */

.align>li{padding: 15px 0;width:23.05%;float:left;margin-right:2.6%;position:relative; margin-bottom: 30px} .align {   text-align: center;       margin: 22px auto; }
.align>li:last-child{margin-right:0}
.align>li>img{height:100%; }
.align>li>div.car_align{position:absolute;top:0;left:0;text-align:left;font-size:15px;color:white;background-color:black;height:100%;width:42%;opacity:.4}
.align>li>div>p:first-child{padding-left:5px;margin:7px;border-left:3px solid #cf5f12}
.align>li>div>p:last-child{padding-left:10px}
.car_main {position: absolute;top: 0;left: 0;color: #fff;}
.align>li .category_img{background-color: #0563ff; width:150px ; height: 150px; padding-top: 25px; border-radius: 150px;}
.align>li .category_img img{margin: 0 auto; max-height: 100px;}
.align li:nth-child(2n) .category_img{background-color: #fec107;}
.align li:nth-child(3n) .category_img{background-color: #ff4d49;}
.align li:nth-child(4n) .category_img{background-color: #a34af0;}




/* Page 2 */

/* bredcrumps */
.bredcrupms {margin: 16px 0 7px 0;}
.bredcrupms ul li {display: inline-block;}
.bredcrupms ul li a{font-size:14px;font-weight:300}
.bredcrupms ul li:last-child ::after{display:none}
.bredcrupms ul li a::after{content:'\f054 ';font-family:'fontawesome';font-size:12px;color:#dc6a1a;padding:0 8px 0 8px}
.LocationDetails {
    display: flex;
    padding-top: 30px;
    justify-content: space-between;
}
.LocationDetails p {
    font-size: 13px !important;
}
/*  mid section*/
.banner{width:100%;display:inline-block; clear: both; margin: 10px 0;}
.banner_left {
  width: 51%;
  background-color: black ;
  margin: auto 56px;
  float: left;
  position: relative;
}
.banner_left img{max-width:100%;margin: auto;}
.icons ul li{ display: inline-block; width: 24%;}
.banner_left .icons{margin-top:11px;text-align:center}
.banner_middle {
  width: 40%;
  border: 1px solid #005099;
  float: left;
  box-shadow: 0px 5px 11px #00000038;
  border-radius: 8px;
  padding: 14px 33px;
}
.banner_middle_left{float:left;width: 100%;}
.banner_middle_left h2{font-weight:400;font-size:23px;margin-bottom:5px}
#banner_middle_left_bottom{margin-top:12px}
#one{font-weight:400;font-size:14px}
.banner_middle_left p{font-weight:400;font-size:14px}
.banner_middle_left p span{font-weight:100}
.banner_middle_right{float:right;width:30%;margin-top: 34px;}
.banner_middle_right p {
    font-size: 18px;
    padding-bottom: 7px;
    margin-left: 18px;
    font-weight: 600;
    color: #af4600;
}
.Details-icons	{
    width: 81%;
    font-size: 24px;
    display: flex;
    justify-content: space-between;
}
.Szx-SiHsds{
  width: 50%;
  margin: auto;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
}
.Szx-SiHsds h2 {
  color: #ffffff;
  background-color: #041856;
  padding: 10px 6px;
  text-align: center;
  margin-bottom: 28px;
  text-transform: uppercase;
  font-size: 20px;
}
.banner_middle_right i{margin-right:3px}
.banner_right {
  width: 40%;
  margin-top: 16px;
  box-shadow: 0px 6px 11px #dbdbdb;
  float: right;
  border: #005099 1px solid;
  border-radius: 15px;
  padding: 9px 23px 15px 31px;
}
.seller_discription {
  display: flex;
  width: 55%;
  margin: 13px 0;
  justify-content: space-between;
}

.sd_seller_cas {
  font-weight: 600;
  font-size: 21px;
  width: 66%;
  padding: 16px 0px;
}
.seller-description_prodcuct-pg {
  float: left;
  width: 50%;
  margin: 33px 82px;
  box-shadow: 0px 6px 11px #dbdbdb;
  border: #005099 1px solid;
  border-radius: 3px;
  padding: 9px 23px 15px 31px;
}
.seller-description_prodcuct-pg p {
  font-size: 15px;
  font-weight: 100;
  margin-top: 11px;
}
.seller-description_prodcuct-pg h4 {
  border-bottom: 1px solid #bcbcbc;
  font-weight: 700;
  font-size: 18px;
  padding: 13px 0;
}
.seller_discription .fa-user-circle-o{
  font-size: 64px;
  color: #005099;
}
.Ex-ydc_as{font-size: 14px !important;
  padding: 6px 0;}
.banner_right .rating{display:inline-block;font-size:20px}
.rating label{color:#ccc;margin-right:6px;float:right}
.rating input[type=radio]{display:none}
.rating input[type=radio]:checked~label{color:#fdd836}
.rating label:hover{color:#fdd836}
.rating label:hover ~ label{color:#fdd836}
.banner_right_content{margin-top:30px}
.banner_right_content h4{font-size:23px;font-weight:500;margin-bottom:5px}
.banner_right_content p{font-size:14px}
.banner_right_content span{font-size:11px;float:left;margin-top:15px}
.rating_right{float:right;font-size:18px;padding:3px}
.five_reviews{font-size:13px;font-weight:500}
.banner_right_content_lower .fa.fa-phone{font-size:20px;font-weight:700;padding:1px 7px}
.banner_right_content_lower {
  font-size: 14px;
  font-weight: 400;
}
.banner_right_content_lower button {
  color: #fff;
  background-color: #ffffff;
  padding: 10px 36px;
  border-radius: 9px;
  border: 2px solid #000000;
  outline: 0;
  /* border: 0; */
  font-weight: 400;
  font-size: 15px;
  margin-top: 24px;
  font-family: poppins;
  width: 100%;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
}
.PrOductListIng .bredcrupms ul li:first-child{width: 35px !important;}
.PrOductListIng .bredcrupms ul li{width: 113px !important; margin-left: 27px;min-height: 0 !important; border: none !important;}
.informationDetailsPage{  font-size: 14px;
  align-items: start;
  margin: auto;
  width: 95%;
  color: #005099;
  margin-top: 130px;}
.informationDetailsPage h1{font-size: 23px; color: #000000;  font-weight: bold;}
.informationDetailsPage h3{font-size: 18px;margin: 10px 0;   color: #000000;  font-weight: bold;}

.categoriesHading{
  width: 100%; clear: both;
}
.categoriesHading p {
  color: black;
  font-size: 31px;
  font-weight: 600;
  padding: 23px 0;
}
.informationDetailsPage h2{
  margin: 10px 0;
  color: black;
  font-size: 19px;
  font-weight: 900;
}
.Categories_menu ul {
  margin: auto;
  width: 98%;
  display: flex;
}
.Categories_menu ul .cateSelected {
  width: 19%;
  text-align: center;
  min-width: 18px;
  padding: 14Px 0px 10px 5px;
  font-size: 15px;
  border: none;
  background: white;
  margin: 0 !important;
}

.selected_categ {
  background: #0050991c;
  border-bottom: 3px solid #005099 !important;
  width: 19%;
  text-align: center;
  padding: 14Px 0px 10px 5px;
  font-size: 15px;
  margin: 0 !important;
}
.PostImage {
  width: 83px;
  height: 73px;
  border: 1px solid #ffffff;
  margin: 9px;
  box-shadow: 0px 2px 5px 0px rgba(0,0,0,0.14) , 0px 1px 10px 0px rgba(0,0,0,0.12) , 0px 2px 4px -1px rgba(0,0,0,0.2)  ;
  overflow: hidden;
  border-radius: 8px;
}
.postImage_main{display: flex;}
/* 7 pages CSS */
.css-nen11g-MuiStack-root{margin: 20px 0 0;}
.logo_left{width:50%;float:left}
.logo_right{width:50%;float:right;text-align:right}
.heading{text-align:center}
.login{text-align:center}
.login h2{font-weight:500}
.login_email input[type="email"]{font-size:22px;width:100%;border:#10a096 2px solid;padding:4px;border-radius:5px}
.login_advise{text-align:center;padding:2% 0;background-color:#fffff1;margin:2% 0}
.login_advise p{color:black}
.submit_button input[type=submit]{width:100%;padding:10px;font-size:22px;color:#98a7a2;border:0;cursor:pointer}
.submit_advise{text-align:center;margin:2% 0}
.submit_advise p{color:#adafa4}
.icon{text-align:right}
.login_with_email>ul>li{margin:2% 0;text-align:center;position:relative}
.login_with_email>ul>li>button{width:100%;font-size:16px;padding:10px 5px 10px 40px;border:black 1px solid;border-radius:5px;text-align:left;background-color:transparent;font-weight:500;font-family:'poopins'}
.login_with_email>ul>li:nth-child(3)>button{color:#9fadae;border:#9fadae 1px solid}
.login_with_email>ul>li:nth-child(3)>i{color:#9fadae;background-color: transparent;}
.login_with_email>ul>li>a{color:black;border-bottom:black 1px solid;font-weight:500}
.login_with_email>ul>li>h6{font-size:12px;font-weight:300}
.login_with_email>ul>li>i{position:absolute;top:8px;left:10px;font-size:24px}
.login_with_email>ul>li>.terms>p{color:#bcbcb4;font-size:12px}
.login_with_email>ul>li>.terms>p>span{color:#708ad7}
.login_with_email>ul>li>.terms{display: inline-block;}

.start_selling_left{width:20%;float:left}
.start_selling_left>ul>li>ul{border:black 1px solid;margin:2% 0 2% 0}
.start_selling_left>ul>li>ul>li{margin:2% 0 2% 0}
.start_selling_left>ul>li>ul>li:first-child{background-color:#dae2e4;margin:0}
.start_selling_left>ul>li>ul>li>h3{font-weight:500}
.start_selling_left>ul>li>p{text-align:center;font-size:10px}
.start_selling_left>ul>li:last-child{text-align:center}
.start_selling_left>ul>li>button{border:0;border-bottom-color:currentcolor;border-bottom-style:none;border-bottom-width:medium;background-color:transparent;font-size:14px;font-weight:500;border-bottom:#3d575c 1px solid;color:#3d575c}
.start_selling_right{width:76%;float:left;padding-left:2%}
.start_selling_right>.profile_holder{width:100%;float:left;padding-left:2%}
.start_selling_right>.profile_holder>h3{float:left;font-size:35px}
.start_selling_right>.profile_holder>button{background-color:white;border:black 1px solid;font-size:13px;padding:7px;border-radius:4px;font-weight:500;margin-left:1%}
li{list-style:none}
a{display:inline-block;text-decoration:none}
.profile_box{width:100%;float:left;padding:2%}
.profile_box>.profile_box_left{width:25%;float:left}
.profile_box>.profile_box_right{width:75%;float:left}
.profile_box>.profile_box_left>ul>li{padding:5px 0}
.profile_box>.profile_box_left>ul>li>a{color:#2a575f;border:1px solid #2a575f;width:80%;text-align:center;padding:4px 0;border-radius:5px}
.profile_box>.profile_box_left>ul>li>p{font-size:14px;color:#aeb9bb}
.profile_box>.profile_box_left>ul>li{padding-top:6px}
.profile_box>.profile_box_left>ul>li>a{color:#2a575f;border:1px solid #2a575f;width:80%;text-align:center;padding:4px 0;border-radius:5px;margin-top:10px}
.profile_box>.profile_box_right{border:1px solid #c1c1c1}
.profile_box>.profile_box_right>form>ul{padding:2%}
.profile_box>.profile_box_right>form>ul>li{font-size:16px;font-weight:500;color:#2a575f}
.profile_box>.profile_box_right>form>ul>li>h3{margin:2% 0;font-size:12px;color:#110a02}
.profile_box>.profile_box_right>form>ul>li>.basic_information{margin-bottom:4%;float:left;border-bottom:1px solid #c1c1c1}
.profile_box>.profile_box_right>form>ul>li>.basic_information>ul{width:48%;float:left;margin:1%}
.profile_box>.profile_box_right>form>ul>li>.basic_information>ul:nth-child(2){width:40%;float:left;margin:2% 5% 0 1%;padding:10px;border:#cad9de 1px solid}
.profile_box>.profile_box_right>form>ul>li>.basic_information>ul>li>input{padding:2%;font-size:15px;border-radius:5px;margin:2% 0;width:100%;border:#cad9de 1px solid}
.profile_box>.profile_box_right>form>ul>li>.basic_information>ul>li>textarea{font-size:13px;padding:5px 2px 0 2px;width:100%;resize:vertical;margin:10% 0;border:#cad9de 1px solid}
.profile_box>.profile_box_right>form>ul>li>.basic_information>ul>li>h3{font-size:15px}
.profile_box>.profile_box_right>form>ul>li>.basic_information>ul>li>p{font-size:10px}
.profile_box>.profile_box_right>form>ul>li>.contact_information{width:100%;float:left;clear:both;padding:7px;border-bottom:1px solid #c1c1c1}
.profile_box>.profile_box_right>form>ul>li>.contact_information>ul>li{width:100%;margin:1% 0;float:left}
.profile_box>.profile_box_right>form>ul>li>.contact_information>ul>li>input[type="text"]{width:50%;font-size:15px;padding:1%;float:left;border:#cad9de 1px solid;border-radius:5px}
.profile_box>.profile_box_right>form>ul>li>.contact_information>ul>li>p{width:50%;float:left;padding:0 0 0 2%;font-size:10px}
.profile_box>.profile_box_right>form>ul>li>.additional_information{clear:both;float:left;width:100%;margin-top:4%;border-bottom:1px solid #c1c1c1}
.profile_box>.profile_box_right>form>ul>li>.additional_information>.additional_information_left{width:50%;float:left}
.profile_box>.profile_box_right>form>ul>li>.additional_information>.additional_information_left h3{font-size:12px;color:#110a02}
.profile_box>.profile_box_right>form>ul>li>.additional_information>.additional_information_left h5{font-size:13px;color:#9ea9ad}
.profile_box>.profile_box_right>form>ul>li>.additional_information>.additional_information_left p{font-size:10px;color:#9fa2a1}
.profile_box>.profile_box_right>form>ul>li>.additional_information>.additional_information_right{width:50%;float:left;margin-bottom:2%}
.profile_box>.profile_box_right>form>ul>li>.additional_information>.additional_information_right>a{border:1px solid #9cb0af;width:65%;padding:1% 24%;color:black;margin-top:7%;text-align:center;font-size:10px;color:#4f636c;border-radius:5px}
.profile_box>.profile_box_right>form>ul>li>ul>li>.discard{width:50%;float:left;margin-top:1%}
.profile_box>.profile_box_right>form>ul>li>ul>li>.discard>h3{font-size:12px;color:#839ca0;width:fit-content;border-bottom:#97abaa 1px solid}
.profile_box>.profile_box_right>form>ul>li>ul>li>.save_changes{text-align:right}
.profile_box>.profile_box_right>form>ul>li>ul>li>.save_changes>a{border:#002b32 1px solid;background-color:#002b32;color:white;padding:5px;font-size:11px;border-radius:5px;margin-top:1%}
.container_olx1{margin:2%}
.container_olx_2{margin:2%;text-align:center}
.container_fluid3{text-align:left;padding:5%}
li{list-style-type:none;margin:0;padding:0}
.icons{display:flex}
.back_arrow_icon{text-align:left;width:50%;font-size:x-large;font-weight:1}
.corss_icon{text-align:right;width:50%;font-size:x-large}
.next_button{width:100%;height:100%;margin:20% 0 0;border-radius:5px;border-width:0;padding:1.5%;font-weight:600;font-family:poppins;font-size:large}
.input_phone_number input::placeholder{font-size:medium;color:#7b838a}
.input_phone_number{display:flex;position:relative;width:100%;height:100%;margin-top:10%}
.input_phone_number input{padding:2% 12%;text-align:left;width:100%;height:100%;border:#49b8b8 4px solid;font-size:larger;border-radius:5px}
.input_phone_number p{padding:2% 2%;border-right:2px solid;position:absolute;color:#d6dbdf;top:2px}
.Enter_your_phone_number{margin-bottom:20px}
.Enter_your_phone_number p{font-weight:700;font-size:20px;color:#022b30}
.olx_header p{font-weight:700;font-size:40px;color:#012c32;display:inline-block;text-align:center;vertical-align:middle}
.forgot_password{text-align:left;margin-top:1%}
.show_password{position:absolute;right:2%;top:32%;border:0}
.forgot_password a{text-decoration:none}
.log_in{width:100%;margin:15% 0 0;background-color:#042f38;color:white;border-radius:5px;padding:1.5%;font-weight:600;font-family:poppins}
.Welcome_back{margin:20px;display: inline-block;}
.Welcome_back >p>span{color:#012a2e;font-weight:600}
.show_password{color:#39aba1;background-color:white}
.input_password{display:flex;position:relative;width:100%;height:100%;margin-top:2%}
.input_password input{padding:2% 2%;text-align:left;width:100%;height:100%;border-radius:5px;border:#737979 1px solid;font-size:larger}
.input_password p{padding:10px 30px;border-right:2px solid;position:absolute;color:#d6dbdf}
.olx1>p{margin-top:5%;color:#96929d;font-size:small}
.next_button p{color:#96929d}
.top_box{margin-bottom:20px}
.top_box1{width:100%;float:left;margin-bottom:20px}
.top_box1 img{float:left}
.profile_data{width:80%;float:left}
.top_box_1_1{clear:both}
.top_box_1_1 h4{color:#0b262c}
.top_box_1_1 p{color:#8a9799;font-size:13px}
.profile_data>a{color:#566d6d}
.profile_data>h3{color:#052923}
.profile_data>p{color:#99a2a1}
.middle_box li i{float:left;width:auto;padding:4px}
.below_box li i{float:left;width:auto;padding:4px}
li i{float:left;width:auto;padding:4px}
.middle_box{margin:20px 0;color:#132625}
.middle_box>ul>li>i{padding:5px 5% 0 0;color:#3b4e52}
.below_box{margin:20px 0;color:#132625}
.below_box>ul>li>i{padding:5px 5% 0 0;color:#3b4e52}
.below_box2>ul>li>i{padding:5px 5% 0 0;color:#3b4e52}
.below_box2{margin:20px 0;color:#132625}
.container_fluid3 li{list-style-type:none;margin:20px 0;padding:0}
.container_fluid3>hr{color:#d8dad9}
.first_popup{text-align:center}

p.product_Title {
    font-size: 12px;
    font-weight: 600; word-break: break-all;
}

.search_suges {
    position: absolute;
    background: #ffffff;
    width: 100%;
    box-shadow: 0px 5px 9px 1px #8d898954;
    top: 31px;
    border-radius: 14px;
    padding: 15px 0;
	z-index: 1111
}
.search_suges_locations {
  position: absolute;
  text-align: start;
  background: #ffffff;
  width: 100%;
  box-shadow: 0px 5px 9px 1px #8d898954;
  top: 31px;
  border-radius: 6px;
  padding: 15px 11px;
  z-index: 1111;
}

.maindropdown {
  display: none;
}
#vertical-tab-0 > div.css-1dhh7s3-MuiStack-root {
  display: flex;
  align-items: center;
}

/* media-query Tablet */
@media (min-width: 767px) and (max-width:1024px){

/* Page1 */

/* banner */
.banner_section .banner_text {position: absolute;   top: 11px;   left: 74px;   font-size: 27px;   color: #fff; }
.banner_section .banner_text h2{width: 100%; font-size: 30px ;}
.banner_text button{padding: 7px 15px;}
.banner_section .banner_text h2 span {   color: #012e6d;   font-weight: 600;   float: left;   padding-right: 6%; }

/* fresh recommendation */
.fresh_recommendation_top li {     width: 49%;    margin-right: 0;  }

/* center banner */
.banner_section .banner_nav {   position: absolute;   top: 5%;   right: 7%;   font-size: 33px; }
.banner_nav h2{  font-size: 31px;} .banner_section .banner_nav h3 {   font-weight: 300;   text-align: end; }

/* icons */
.align > li > div.car_align {    width: 72%;    }
.jobs ul li {   width: 48%;   display: inline-block;   margin-top: 1.3%;   padding: 6px 18px;   border: #b9b9b9 1px solid; }



/* Page2 */
  .container{padding:0 2%}
  .bredcrupms ul li a::after{padding:0 0 0 2px}
  .relatedtext{float:right;width:100%;padding:7px 12px 0 12px}
  .related_ads img{max-width:100%;width:100%;float:left}
  .footer ul li:last-child{padding-right:6%}
  .related_ads li{width:31.9%}
  .banner_left{width:100%; margin-bottom: 8%;}
  .banner_left img {     max-width: 100%;     width: 100%;   }
  .banner_left ul {   margin: 0 auto;   }
  .banner_right{width:50%;float:right;border:#fddac3 2px solid;min-height:437px;padding:2%}
  .banner_middle{width:50%;float:left;padding-left:3%}
  .banner_middle_left{width: 80%;}
  .banner_right_content{margin-top:14px}
  .banner_right_content_lower button{width: 100%;}
  .banner_right_content_lower{margin-top:32%;font-size:14px;font-weight:400}
  .banner_middle_right{font-size:19px;float:right;width:100%;margin-top:10%}
  .bredcrupms{padding-top:1.5px}

  /* header */
.header{margin-bottom: 5%;}
  .sell_btn_container{width: 13%;}
  .location_btn_container::before{top: 5px;    left: 28px;}
  .location_btn_container::placeholder{font-size: 14px;}
  .location_btn_container{width: 21%;}
  .location_btn_container > input[type="search"]{padding: 5% 0 5% 23%;}
  .search_btn_container::before {top: 5px;}
  .search_btn_container > input[type="search"]{padding: 4% 0 4% 18%;}
}
.product_img {
  float: left;
  width: 50%;
}








/* media query */

@media (min-width: 1px) and (max-width:766px){

/* header */
.header_logo{width:fit-content}
.header_block{padding: 7px 0;}
.header_categories{padding-top:2px}
.menu_btn_container .fa.fa-bars{font-size:21px;padding-top:3%;color:#eb711e}
.search_btn_container>input[type="search"]{padding:3% 0 3% 23%}
.location_btn_container::before {   content: '\f041';   color: black;   position: absolute;   top: 18%;   left: 8%;   font-family: 'fontawesome';   font-size: 90%; }
.location_btn_container>input[type="search"]{width:94%;padding:5% 0 5% 21%;background-color:#e9e9e9;border-radius:15px;border:0;outline:0}
.message_btn_container .fa.fa-comment{color:#eb711e;display:block;font-size:23px}
.sell_btn_container{width: 16%;
  padding: 0;
  font-size: 14px;}
.message_btn_container{width:10%}
.location_btn_container{width: 26%;
  font-size: 13px;
  padding: 0px;
}
.searchHandler form > button {
  bottom: 2px !important;
  right: 8px !important;

}
.searchHandler {
  width: 27% !important;
  font-size: 13px;
}
.menu_btn_container{ width: 14%;
  left: -8px;
  top: -4px;}
.sell_btn_container button{width:92%;box-shadow:0 1px 3px 1px #e4e4e3}

/* page 1 banners */
.banner_section .banner_text{position:absolute;top:12%;left:9%;font-size:36px;color:#fff;width:29%}
.banner_section .banner_text h3 {   font-weight: 300;   font-size: 4vw; }
.banner_section .banner_text h2{font-weight:600;font-size:4vw;width:100%}
.banner_text button{display:block;    
  padding: 4px;
  border-radius: 6px;
  color: #21487f;
  background-color: #fff;
  font-weight: 600;
  font-size: 10px;
  outline: 0;
  border: 0;
  width: 105%;
  margin-top: 67%;
}
.align > li .category_img {
  background-color: #0563ff;
  width: 90px;
    height: 90px;
  padding-top: 11px;
  border-radius: 150px;
}
.category_name {
  font-size: 12px;
  padding: 15px 0px 15px 16px;
}
ul.ads_focus > .card {
  width: 48%;
  margin-right: 6px;
}
.image_productListing {
  height: 139px;
}
ul.ads_focus > .card h2 {
  font-size: 19px;
}
ul.ads_focus > .card p {
  font-size: 13px;
}
.related_ads h2 {
  font-size: 15px;
}
.drawer_dropdown ul {
  width: 233px !important;
}
.drawer_dropdown ul li{
 font-size: 12px;
}
.addViesad {
  padding-top: 22px;
  color: #585656;
  font-size: 13px;
}
.filtterSwitch {
  font-size: 11px;
}
.searchiv {
  width: 39% !important;
}
.Categories_menu ul .cateSelected {
  width: 31%;
  min-width: 87px;
}
.Categories_menu ul {
  overflow-y: scroll;
}
.banner_left {
  margin: auto 0px;
}
.banner_section .banner_nav {   position: absolute;   bottom: unset;   right: 12%;   font-size: 4vw;   text-align: center;   top: 0;   padding: 2vw 0 0 0; }
.banner_section .banner_nav h3{font-weight:300;text-align:end}
.banner_section .banner_text h2 span {   color: #012e6d;   font-weight: 600;   float: none;   padding-left: 4%; }
.banner_nav h2 {   font-size: 16px;   text-align: right;   font-weight: 600;   width: 100%;   margin: 0 auto; }
.banner_section .banner_nav button{border:0;padding:3%;border-radius:9px;color:#800000;font-weight:600;margin:0;background-color:#fff;font-size:3vw; font-family: poppins;}



/* Align  icons class*/
.align { text-align: center; width: 91%; margin: 19px auto; width: 100%;}
.car_main{font-size: 10px;padding: 8px;}
.align > li > div > p:last-child{padding-left: 3px;}
.align > li a {

  width: 80%;
  margin-top: 12px;
}
.car_main {
  font-size: 12px;
  padding: 7px 0;
  font-weight: 600;
  text-align: center;
  width: 100%;
}
.align > li > div > p:first-child{margin: 0;}


/* Mid section page 1 */
.mid_section_lft {  width: 100%; margin-bottom: 8%;}
.mid_section_rgt {width: 100%; }
.fresh_recommendation_top li {width: 100%;}
.mid_section_rgt3 ul li {width: 100%;}
.mid_section_rgt2 .sec_text {padding: 1%; }
.mid_section_rgt2 {display: flex; flex-direction: column-reverse; }
.mid_section_rgt2 img {width:100%;}

.mid_section_rgt2 .sec_text {padding: 2%; width: 100%; }
.all_border{min-height: 0;}
.all_border .text {  padding: 5px 18px;}
.all_border .text p{ font-size: 12px;}
.all_border .text h4{font-size: 5vw;}
.all_border #heart {   position: absolute;   top: 3%;   right: 8%;   background-color: white;   border-radius: 7px;   padding: 4px 11px;   border: 0;   outline: 0;   left: unset; }
.mid_section_rgt3 ul li h5, .mid_section_rgt3 ul li h6,  .mid_section_rgt3 ul li p {font-weight: 300;}
/* login page */
.Szx-SiHsds{width: 100%;}
.Szx-SiHsds .css-1anrcgn-MuiFormControl-root-MuiTextField-root{ margin: 12px 0px 12px 20px;}

.Szx-SiHsds .css-n03ziw-MuiButtonBase-root-MuiButton-root{margin: 12px 0px 12px 18px;}
/* Footer */
  .footer{padding-left:5%}
  .footer ul li>div{width:100%}
  .footer ul>li{width:100%;margin:12px auto 3px auto;font-weight:300}
  .footer ul li:last-child{margin-bottom:19px}
  .footer ul li:last-child{padding-right:0}
  .footer ul{width:100%;text-align:center}
  .social_media{justify-content: center;}

/* bredcrumps */
.bredcrupms ul li a::after{content:'\f054 ';font-family:'fontawesome';font-size:86%;padding-right:0%}
.bredcrupms ul li a{font-size:74%}

/*  Page 2*/
.banner_left{width:100%}
.banner_middle{width:100%;}
.Detailsprice {
  font-size: 25px;
}
.banner_right_content {
  margin-top: 6px;
}
.banner_middle_left{width:100%}
.banner_middle_right{float:left;width:30%;display:flex;align-items:center;gap:10%;padding-top:21px}
.banner_right{width:100%;margin-top:17px;min-height:0;padding: 2% 4%;}
.banner_right_content_lower{margin-top: 4vw;}
.seller_discription .fa-user-circle-o {
  font-size: 53px;
  color: #005099;
}
.seller-description_prodcuct-pg {
  width: 95%;
  margin: 10px;
}
/* Related ads section */
.related_ads li {width: 100%; margin:0 0 20px 0}
.related_ads .arrow_slider_right {left: 45%; top: 40%; width: 5%; height: 25%; line-height: 4; }
.related_ads .arrow_slider_left {left: 0; top: 40%; width: 5%; height: 25%; line-height: 4;}
.relatedtext {float: left; width: 50%; padding: 5% 5% 0 5%;}
.one{font-size:5vw;font-weight:300}
.two{font-size:12px;font-weight:300;padding-top:4%; font-family: poppins;}
.three {font-size: 9px; padding-top: 2%; width: 100%;font-weight: 300;}
.mid_section_rgt3 ul li {width: 100%; margin-bottom: 1vw;}
.relatedtext p span{margin: 0;}
#heart{position:absolute;top:3%;left:37%}
#featured{top:3%;left:0;padding:1% 2%}
.mobile_menu{position:fixed;width:60%;top:0;right:-100%;height:100vh;z-index:4}
.container_fluid.header_menu{position:relative}
.mobile_menu>ul{color:white;background-color:black;height:100%;opacity:.9}
.mobile_menu>ul>li{border-bottom:1px solid white;width:100%;padding:.5vw 3vw}
.active{right:0}
.mobile_menu>ul>li:first-child{text-align:right;margin-bottom:3vw;border-bottom:0}
#box{color:white}
.mobile_menu>ul>li>ul>li{width:100%}
.mobile_menu>ul>li>ul{width:100%;display:none}
.arrow_slider_left{top:36%; left: 0%;}
.arrow_slider_right{top:36%; right: 0%;}
.container{margin:0 auto;padding:0 1%}
.Szx-SiHsds h2 {
  font-size: 16px;
}
/* jobs */
.jobs ul li {width: 100%; display: inline-block; margin-bottom: 2.1%; padding: 4%; border: #b9b9b9 1px solid;}


/* 7 pages media query css */

.profile_box>.profile_box_left{width:100%;margin-bottom:2%}
.profile_box>.profile_box_left>ul>li>a{width:100%;margin:0}
.profile_box>.profile_box_right{width:100%}
.profile_box>.profile_box_right>form>ul>li>.basic_information{margin-top:2%;padding-bottom:2%}
.profile_box>.profile_box_right>form>ul>li>.basic_information>ul:nth-child(2){width:48%;padding:2px;margin:0}


}

.related_ads_heading li {
  border: none;
}




/* carousel */
.carousel {
	position: relative
}

.carousel.pointer-event {
	touch-action: pan-y
}

.carousel-inner {
	position: relative;
	width: 100%;
	overflow: hidden
}

.carousel-inner::after {
	display: block;
	clear: both;
	content: ""
}

.carousel-item {
	position: relative;
	display: none;
	float: left;
	width: 100%;
	margin-right: -100%;
	-webkit-backface-visibility: hidden;
	backface-visibility: hidden;
	transition: transform .6s ease-in-out
}
span.visually-hidden {
  display: none;
}





@media (prefers-reduced-motion:reduce) {
	.carousel-item {
		transition: none
	}
}

.carousel-item-next,
.carousel-item-prev,
.carousel-item.active {
	display: block
}

.active.carousel-item-end,
.carousel-item-next:not(.carousel-item-start) {
	transform: translateX(100%)
}

.active.carousel-item-start,
.carousel-item-prev:not(.carousel-item-end) {
	transform: translateX(-100%)
}

.carousel-fade .carousel-item {
	opacity: 0;
	transition-property: opacity;
	transform: none
}

.carousel-fade .carousel-item-next.carousel-item-start,
.carousel-fade .carousel-item-prev.carousel-item-end,
.carousel-fade .carousel-item.active {
	z-index: 1;
	opacity: 1
}

.carousel-fade .active.carousel-item-end,
.carousel-fade .active.carousel-item-start {
	z-index: 0;
	opacity: 0;
	transition: opacity 0s .6s
}

@media (prefers-reduced-motion:reduce) {
	.carousel-fade .active.carousel-item-end,
	.carousel-fade .active.carousel-item-start {
		transition: none
	}
}

.carousel-control-next,
.carousel-control-prev {
	position: absolute;
	top: 0;
	bottom: 0;
	z-index: 1;
	display: flex;
	align-items: center;
	justify-content: center;
	width: 15%;
	padding: 0;
	color: #fff;
	text-align: center;
	background: 0 0;
	border: 0;
	opacity: .5;
	transition: opacity .15s ease
}

@media (prefers-reduced-motion:reduce) {
	.carousel-control-next,
	.carousel-control-prev {
		transition: none
	}
}

.carousel-control-next:focus,
.carousel-control-next:hover,
.carousel-control-prev:focus,
.carousel-control-prev:hover {
	color: #fff;
	text-decoration: none;
	outline: 0;
	opacity: .9
}

.carousel-control-prev {
	left: 0
}

.carousel-control-next {
	right: 0
}

.carousel-control-next-icon,
.carousel-control-prev-icon {
	display: inline-block;
	width: 2rem;
	height: 2rem;
	background-repeat: no-repeat;
	background-position: 50%;
	background-size: 100% 100%
}

.carousel-control-prev-icon {
	background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23fff'%3e%3cpath d='M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z'/%3e%3c/svg%3e")
}

.carousel-control-next-icon {
	background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23fff'%3e%3cpath d='M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e")
}

.carousel-indicators {
	position: absolute;
	right: 0;
	bottom: 0;
	left: 0;
	z-index: 2;
	display: flex;
	justify-content: center;
	padding: 0;
	margin-right: 15%;
	margin-bottom: 1rem;
	margin-left: 15%;
	list-style: none
}

.carousel-indicators [data-bs-target] {
	box-sizing: content-box;
	flex: 0 1 auto;
	width: 30px;
	height: 3px;
	padding: 0;
	margin-right: 3px;
	margin-left: 3px;
	text-indent: -999px;
	cursor: pointer;
	background-color: #fff;
	background-clip: padding-box;
	border: 0;
	border-top: 10px solid transparent;
	border-bottom: 10px solid transparent;
	opacity: .5;
	transition: opacity .6s ease
}

@media (prefers-reduced-motion:reduce) {
	.carousel-indicators [data-bs-target] {
		transition: none
	}
}

.carousel-indicators .active {
	opacity: 1
}

.carousel-caption {
	position: absolute;
	right: 15%;
	bottom: 1.25rem;
	left: 15%;
	padding-top: 1.25rem;
	padding-bottom: 1.25rem;
	color: #fff;
	text-align: center
}

.carousel-dark .carousel-control-next-icon,
.carousel-dark .carousel-control-prev-icon {
	filter: invert(1) grayscale(100)
}

.carousel-dark .carousel-indicators [data-bs-target] {
	background-color: #000
}

.carousel-dark .carousel-caption {
	color: #000
}


/* custom carosel */
.banner_section img .carousel .carousel-control-next, .banner_section .carousel .carousel-control-prev, .banner_section .carousel .carousel-control-prev-icon, .banner_section .carousel .carousel-control-next-icon, .banner_section .carousel .carousel-indicators {
	display: none;
  }
  .all_border .carousel .carousel-control-next, .all_border .carousel .carousel-control-prev {
	justify-content: end;
	width: auto;
	opacity: 1;
  }
  .all_border .carousel .carousel-control-prev-icon,.all_border .carousel .carousel-control-next-icon {
	background-color: #d26114;
	width: 20px;
	height: 3rem;
  }
  .all_border .carousel .carousel-indicators{
	display: none;
  }
  .fresh_rec {
	float: left;
	width: 50%;
  }
  .fresh_rec .carousel .carousel-control-next, .fresh_rec .carousel .carousel-control-prev {
	justify-content: end;
	width: auto;
	opacity: 1;
  }
  .fresh_rec .carousel .carousel-control-prev-icon,.fresh_rec .carousel .carousel-control-next-icon {
	background-color: #d26114;
	width: 20px;
	height: 3rem;
  }
  .fresh_rec .carousel .carousel-indicators{
	display: none;
  }
  .Cars{
	float: left;
  width: 40%;
  }
  .Cars img{max-height: 150px;}
  .Cars .carousel .carousel-control-next, .Cars .carousel .carousel-control-prev {
	justify-content: end;
	width: auto;
	opacity: 1;
  }
  .Cars .carousel .carousel-control-prev-icon,.Cars .carousel .carousel-control-next-icon {
	background-color: #cccccc;
	width: 20px;
	height: 150px;
  }
  .Cars .carousel .carousel-indicators{
	display: none;
  }
  .Furniture{
	float: left;
	width: 50%;
  }
  .CarFurnitures .carousel .carousel-control-next, .Furniture .carousel .carousel-control-prev {
	justify-content: end;
	width: auto;
	opacity: 1;
  }
  .Furniture .carousel .carousel-control-prev-icon, .Furniture .carousel .carousel-control-next-icon {
	background-color: #d26114;
	width: 20px;
	height: 3rem;
  }
  .Furniture .carousel .carousel-indicators{
	display: none;
  }
  .top_sldr{
	width: 80%; float: right;
  }
  .Real_Estate{
	float: left;
	width: 50%;
  }
  .Real_Estate .carousel .carousel-control-next, .Real_Estate .carousel .carousel-control-prev {
	justify-content: end;
	width: auto;
	opacity: 1;
  }
  .Real_Estate .carousel .carousel-control-prev-icon, .Real_Estate .carousel .carousel-control-next-icon {
	background-color: #d26114;
	width: 20px;
	height: 3rem;
  }
  .Real_Estate .carousel .carousel-indicators{
	display: none;
  }









/* thumbnail */
#sync1 .item {
  margin: 5px;
  color: #fff;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
  text-align: center;
}

#sync2 .item {
  background: #c9c9c9;
  /* padding: 10px 0px; */
  margin: 5px;
  color: #fff;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
  text-align: center;
  cursor: pointer;
}

#sync2 .item h1 {
  font-size: 18px;
}

#sync2 .current .item {
  background: #0c83e7;
}

.owl-theme .owl-nav [class*="owl-"] {
  transition: all 0.3s ease;
}

.owl-theme .owl-nav [class*="owl-"].disabled:hover {
  background-color: #d6d6d6;
}

#sync1.owl-theme {
  position: relative;
}

#sync1.owl-theme .owl-next,
#sync1.owl-theme .owl-prev {
  width: 22px;
  height: 40px;
  margin-top: -20px;
  position: absolute;
  top: 50%;
}

#sync1.owl-theme .owl-prev {
  left: 10px;
}

#sync1.owl-theme .owl-next {
  right: 10px;
}

/* animate fadin duration 1.5s */
.owl-carousel .animated {
  animation-duration: 1.5s !important;
}

/* 輪播的前後按鈕背景調大 */
#sync1.owl-theme .owl-next,
#sync1.owl-theme .owl-prev {
  width: 35px !important;
  height: 55px !important;
}

#sync1 svg {
  width: 22px !important;
}


.Categories {
    float: left;
	padding: 10px 0;
}
.Categories h6 {
    float: left;
    text-align: left;
}
#vertical-tabpanel-0 {
	width: 100%;
  }
  #vertical-tabpanel-0 > .MuiBox-root.css-19kzrtu {
	padding: 0;
  }
  .makeStyles-messageBlue-10{
	  /* min-width: 190px;
	  min-height: 45px;
	  padding: 5px 10px 23px !important; */
	  max-width: 56vw;
  }
  #style-1 {
    overflow: unset;
  }
  .makeStyles-messageOrange-11{
	/* min-width: 190px;
	min-height: 45px;
	padding: 5px 10px 23px !important; */
	max-width: 56vw;
  }
  .css-e53awj-MuiStack-root {
    display: flex;
    align-items: center;
  }
  .MuiPaper-root.makeStyles-paper-1.MuiPaper-elevation1.MuiPaper-rounded{
	  box-shadow: none;
  }
  #style-1 {
	box-shadow: none;
	margin-bottom: 10px;
  }
  .MuiBox-root.css-19kzrtu, .MuiTypography-root.MuiTypography-body1.css-ahj2mt-MuiTypography-root, .MuiTypography-root.MuiTypography-body1.css-ahj2mt-MuiTypography-root, .makeStyles-container-3, .MuiPaper-root.makeStyles-paper-1.MuiPaper-elevation1.MuiPaper-rounded, #messagebox{
	  height: 100%;
  }
  .MuiPaper-root.makeStyles-paper-1.MuiPaper-elevation1.MuiPaper-rounded {
	padding: 10px;
  }
  .MuiFormControl-root.MuiTextField-root.makeStyles-wrapText-15 {
	margin-right: 10px;
  }
  .MuiTabs-root.MuiTabs-vertical.css-101kjhe-MuiTabs-root {
	overflow: unset;
  }

  .app-main{background-color: #f7f8fa; clear: both; padding: 40px 0; width: 100%; float: left; min-height: 600px;}
.post_job{max-width: 800px; margin: 0 auto; padding-top: 40px;}
.feild_input{width: 100%; text-align: left; padding: 10px 0;}
.feild_input label{font-size: 14px; padding-bottom: 8px; display: block;}
.MuiDataGrid-root{background-color: #ffffff;}
.MuiSelect-select, .MuiOutlinedInput-input{background-color: #ffffff !important;}


.two_input{width: 100%; text-align: left; padding: 10px 0; float: left;}
.two_input li{width: 49%; float: left;}
.two_input li:last-child{float: right;}
.two_input label{font-size: 14px; padding-bottom: 8px; display: block;}


.post_shadow{width: 100%; float: left; box-shadow: 0 0 30px rgba(0,0,0,0.1); padding: 30px;   background-color: #ffffff; position: relative; padding-top: 70px}
.field_output{width: 100%; float: left; padding: 10px 0; text-align: left;}
.field_output label{font-size: 14px; display: block; padding-bottom: 8px;}

.drawer_dropdown{position: relative; text-align: left;}
.drawer_dropdown ul {
  position: absolute;
  top: 50px;
  right: -5px;
  width: 300px;
  background-color: #ffffff;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
  z-index: 60;
}
.searchiv {
  margin-top: 22px;
  width: 25%;
}
.drawer_dropdown ul li{width: 100%; padding: 5px; margin: 0; line-height: 2;}
.drawer_dropdown ul li:hover{background-color: #c0dcf7;}
.field_output .box{width: 100%; height: 270px;}
.fixed_heading{position: absolute; top: 0; left: 0; width: 100%; padding: 10px; background-color: #041856; color: #ffffff; font-size: 20px; text-transform: uppercase; text-align: center;}

@media (max-width:767px) {
  .logo_home{display: none;}
  .align li {
    width: 19%;
    margin-right: 24px;
}
  .top_sldr{width: 100%;}
}

.content_page{padding-top:20px;padding-bottom:20px;width:100%; line-height: 1.9;}
.content_page h1{font-size:36px;padding-bottom:10px; font-weight:700;color:#1a1363; margin: 0; text-align: left;}
.content_page h2{font-size:30px;clear:both; font-weight:700;color:#1a1363;position:relative;margin-bottom:15px; padding-bottom: 5px;}
.content_page h2:before{content:'';position:absolute;bottom:0px;left:0;width:100px;height:4px;background-color:#0192ef}
.content_page h2 strong{font-weight:700}
.content_page h3{font-size:21px;padding-top:10px;;font-weight:700;color:#1a1363}
.content_page h4{font-size:20px;font-weight:700;padding-top:10px;;color:#1a1363}
.content_page h5{font-size:18px;font-weight:700;padding-top:10px;;color:#1a1363}
.content_page .page-content{font-size:17px;font-weight:400;line-height:1.9}
.content_page .page-content strong{font-weight:700;}
.content_page .page-content p{padding-bottom:20px; line-height: 1.9; color:rgb(41, 41, 41);}
.content_page .page-content ul{padding-bottom:20px; line-height: 1.9; color: rgb(41, 41, 41);}
.content_page .page-content a{ color:#0192ef; text-decoration:none}
.content_page .page-content a:hover{color:#ff0000}


.searchHandler {
  float: right;
  width: 18%;
  position: relative;
}

.searchHandler form>input[type="text"] {
  width: 100%;
  padding: 4% 0 4% 13%;
  border-radius: 15px;
  border: 0;
  background-color: #e9e9e9;
  outline: 0;
}

.searchHandler form>button {
  background: none;
  position: absolute;
  bottom: 4px;
  right: 16px;
  border: 0;
  cursor: pointer;
  color: black;
  font-weight: bolder;
  font-size: 16px;
  z-index: 1;
}

@media (max-width: 760px){
  .MuiBox-root.css-11tfsxe {
    display: block;
  }
  .maindropdown {
    display: block;
  }
  .MuiTabs-root.MuiTabs-vertical.css-101kjhe-MuiTabs-root {
    width: 100%;
    display: none;
  }
  /* .productname {
    display: grid;
    width: 100%;
    border: navajowhite;
    background: white;
    grid-template-columns: 48% 48%;
    gap: 4%;
    padding: 10px;
    box-shadow: 0 0 2px #ccc;
    cursor: pointer;
  } */
  .maindropdown > .MuiButtonBase-root.MuiTab-root.MuiTab-textColorInherit.productname.css-nlq0gq-MuiButtonBase-root-MuiTab-root {
    width: 100%;
    display: grid;
    gap: 4%;
    grid-template-columns: 48% 48%;
  }
  #style-1 {
    overflow-y: scroll;
    height: 400px;
    padding: 10px;
  }
}

.owl-carousel .owl-nav.disabled, .owl-carousel .owl-dots.disabled {
  display: block!important;
  margin-top: 0;
  padding: 5px 0;
}
.owl-theme .owl-nav.disabled + .owl-dots{
  display: none;
}
button.owl-prev {
  top: 58px;
  position: absolute;
  left: 0px;
}
button.owl-next {
  top: 58px;
  position: absolute;
  right: 0px;
}
div#AdsFocus button.owl-next {
  top: 148px !important;
  position: absolute;
  right: 36px;
}
div#AdsFocus button.owl-prev {
  top: 148px !important;
  position: absolute;
  left: 36px;
}
.category_img{background-color: #0563ff; width:150px ; height: 150px;  padding: 28px; border-radius: 150px;}

button.owl-next span, button.owl-prev span {
  font-size: 30px;
 
}
.owl-theme .owl-nav [class*='owl-']:hover {
  background: transparent!important;
  color: #0b0a0a!important;
}
div#AdsFocus  button.owl-next span, #AdsFocus button.owl-prev span
{ 
  box-shadow: rgba(31, 31, 31, 0.337) 0px 3px 8px;
  border-radius: 3px;
  padding: 7px;
  font-size: 47px;
}
.PictureHandler{margin: 10px;}
.mx_20{
  margin: 40px 0;
}
.PictureHandler{margin: 10px;}


.modalPopup {
  position: fixed;
  top: 71px;
  font-size: 16px;
  left: 15%;
  width: 70%;
  z-index: 11;
}

.close {
  float: right;
  font-size: 21px;
  font-weight: bold;
  line-height: 1;
  color: #000;
  text-shadow: 0 1px 0 #fff;
  opacity: .2;
}

.close:hover,.close:focus {
  color: #000;
  text-decoration: none;
  cursor: pointer;
  opacity: .5;
}

button.close {
  padding: 0;
  cursor: pointer;
  background: transparent;
  border: 0;
  -webkit-appearance: none;
}

.alert {
  padding: 15px;
  margin-bottom: 20px;
  border: 1px solid transparent;
  border-radius: 4px;
}

.alert h4 {
  margin-top: 0;
  color: inherit;
}

.alert .alert-link {
  font-weight: bold;
}

.alert>p,.alert>ul {
  margin-bottom: 0;
}

.alert>p+p {
  margin-top: 5px;
}

.alert-dismissable {
  padding-right: 35px;
}

.alert-dismissable .close {
  position: relative;
  top: -2px;
  right: -21px;
  color: inherit;
}

.alert-success {
  background-color: #dff0d8;
  border-color: #d6e9c6;
  color: #3c763d;
}

.alert-success hr {
  border-top-color: #c9e2b3;
}

.alert-success .alert-link {
  color: #2b542c;
}

.alert-info {
  background-color: #d9edf7;
  border-color: #bce8f1;
  color: #31708f;
}

.alert-info hr {
  border-top-color: #a6e1ec;
}

.alert-info .alert-link {
  color: #245269;
}

.alert-warning {
  background-color: #fcf8e3;
  border-color: #faebcc;
  color: #8a6d3b;
}

.alert-warning hr {
  border-top-color: #f7e1b5;
}

.alert-warning .alert-link {
  color: #66512c;
}

.alert-danger {
  background-color: #f2dede;
  border-color: #ebccd1;
  color: #a94442;
}

.alert-danger hr {
  border-top-color: #e4b9c0;
}

.alert-danger .alert-link {
  color: #843534;
}

.alert {
  border-radius: 0;
  -webkit-border-radius: 0;
  box-shadow: 0 1px 2px rgba(0,0,0,0.11);
}

.alert .sign {
  font-size: 20px;
  vertical-align: middle;
  margin-right: 5px;
  text-align: center;
  width: 25px;
  display: inline-block;
}

.alert-success {
  background-color: #dbf6d3;
  border-color: #aed4a5;
  color: #569745;
}

.alert-info {
  background-color: #d9edf7;
  border-color: #98cce6;
  color: #3a87ad;
}

.alert-warning {
  background-color: #fcf8e3;
  border-color: #f1daab;
  color: #c09853;
}

.alert-danger {
  background-color: #f2dede;
  border-color: #e0b1b8;
  color: #b94a48;
}

.alert-white {
  background-image: linear-gradient(to bottom,#FFFFFF,#F9F9F9);
  border-top-color: #d8d8d8;
  border-bottom-color: #bdbdbd;
  border-left-color: #cacaca;
  border-right-color: #cacaca;
  color: #404040;
  padding-left: 61px;
  position: relative;
}

.alert-white .icon {
  text-align: center;
  width: 45px;
  height: 100%;
  position: absolute;
  top: -1px;
  left: -1px;
  border: 1px solid #bdbdbd;
}

.alert-white .icon:after {
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
  display: block;
  content: '';
  width: 10px;
  height: 10px;
  border: 1px solid #bdbdbd;
  position: absolute;
  border-left: 0;
  border-bottom: 0;
  top: 50%;
  right: -6px;
  margin-top: -5px;
  background: #fff;
}

.alert-white.rounded {
  border-radius: 3px;
  -webkit-border-radius: 3px;
}

.alert-white.rounded .icon {
  border-radius: 3px 0 0 3px;
  -webkit-border-radius: 3px 0 0 3px;
}

.alert-white .icon i {
  font-size: 20px;
  color: #FFF;
  left: 12px;
  margin-top: -10px;
  position: absolute;
  top: 50%;
}

.alert-white.alert-danger .icon,.alert-white.alert-danger .icon:after {
  border-color: #ca452e;
  background: #da4932;
}

.alert-white.alert-info .icon,.alert-white.alert-info .icon:after {
  border-color: #3a8ace;
  background: #4d90fd;
}

.alert-white.alert-warning .icon,.alert-white.alert-warning .icon:after {
  border-color: #d68000;
  background: #fc9700;
}

.alert-white.alert-success .icon,.alert-white.alert-success .icon:after {
  border-color: #54a754;
  background: #60c060;
}


.MuiButtonBase-root.left-tab-align{
  justify-content: flex-start;
  margin-left: 15px;
}